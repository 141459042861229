import { useState, useEffect } from 'react'

function ScrollToBottomButton(){
    const [ showBtn, setShowBtn] = useState({display:'none'});
    const el = document.querySelector('.rcb-chat-body-container');

    function scrollHandler(){
        if( !el ) return;
        let ch = el.clientHeight;
        if( el.scrollHeight -  el.scrollTop > 2*ch ) setShowBtn({ display:'block' })
        else setShowBtn({ display:'none' })
    }
    useEffect(() => {
        if(!el) {
            console.log(`no handler`)
            /*setTimeout(()=>{
                let ell = document.querySelector('.rcb-chat-body-container');
                if(!ell)      console.log(`no handler again`)
                    ell?.addEventListener("scroll", scrollHandler);
            },5000)*/
            return;
        }
        else console.log(`set handler`)
        el?.addEventListener("scroll", scrollHandler);
    }, [el])

    function scroll(){
        setShowBtn({ display: 'none'});
        let h = el.scrollHeight;
        el.scrollTo(0,h);
     }
    return(
        <div className = "scroll-to-bottom-btn" style =  { showBtn }>
            <button onClick = { scroll }/>
        </div>
    )
}

export default ScrollToBottomButton