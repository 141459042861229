import './styles/App.css';
import ChatBot,{  ChatBotProvider } from "react-chatbotify";
import { ServiceRequest, ServiceResponse, loadContent } from './service/service';
import { _Settings, Styles } from './config/chat.settings'
import { uuidv4, cleanHtml } from './utils'
import {  useEffect, Suspense, useState, createContext } from 'react';
import { ConfigHook } from './service/configHook';
import { ErrorHook } from './service/errorHook';
import { useTranslation } from "react-i18next";
import MediaPopoverContainer from './components/MediaPopoverContainer';
import Gallery from './components/Gallery';
import Error from './config/error';
import BotBubble from './components/BotBubble';
import ScrollToBottomButton from './components/ScrollToBottomButton';
import CancelRequestBtn from './components/CancelRequestBtn';

export const ChatContext = createContext(null);

function App() {
  let errorHandler = null, requestId = null, answerOptions = null, answerContent=null,answerContext=null;
  const { t, i18n } = useTranslation();

  const [isPopoverActive, setPopoverActive] = useState(false);
  const [ contentData, setContentData ] = useState({ src: null, type:null, context: null });
  const [ userId, setCurrentUser ] = useState(null);
  const [ errorEvent, setError] = useState(null);
  const [ cancelBtn, setCancelBtn] = useState({ id: null, show: false});

  const handlePopoverOpen = (src, type, context) => {
    setPopoverActive(true);
    setContentData({ src, type, context })
  };
  const handlePopoverClose = () => {
    setPopoverActive(false);
  };  
  
  
  useEffect(() => {
    function handlePreInjectMessage(event)  {
      if(event.data.message.type == 'object' && event.data.message.content.type.name == 'DataMarker') return;
      let ts = new Date(event.data.message.timestamp);
      let mn = ts.getMinutes();
      mn = mn < 10 ? `0${mn}` : mn;

      ts = `${ts.getHours()}:${mn}`;
      if( event.data.message.sender =='user'){
        let cleanUserInput = cleanHtml(event.data.message.content);
        if(cleanUserInput.dirty){
          event.preventDefault();
        }
        else event.data.message.content = event.data.message.content = cleanUserInput.cleanInput + "<div class='message-timestamp'>"+ ts +"</div>"
      }
      else {//bot bubble: add content and timestamp
        if(event.data.message.type == 'string' )  {

           event.data.message.content  += "<div class='message-timestamp'>"+ ts +"</div>"
        }
        else { //menu 
          }
      }
    };

    window.addEventListener("rcb-pre-inject-message", handlePreInjectMessage);
    return () => {
      window.removeEventListener("rcb-pre-inject-message", handlePreInjectMessage);
    };

    
  }, []);




  async function sendUserMessage( arg ) {
    try {
      console.log(`sendUserMessage ${arg.userInput}`)
      let cleanUserInput = cleanHtml(arg.userInput);
      if( cleanUserInput.dirty ){
        console.log(`sendUserMessage dirty`)
        errorHandler = Error.DANGEROUS_CODE;
      }
      else {
        let result = await ServiceRequest({ text: cleanUserInput.cleanInput, user_id: userId });
        if( result && result.error ){
          errorHandler = result;
        }
        else {
            requestId = result
        }
      }
    } catch(err) {
      console.log(`error while waiting ServiceRequest: ${arg.userInput}`);
      errorHandler = Error.ERROR
    }
  }

  function getLoggedUser(){
    let user_id = localStorage.getItem('known_user');
    if( !user_id ){
      let current_timestamp = new Date().getTime();
      user_id = uuidv4() + '_' + current_timestamp;
      localStorage.setItem('known_user', user_id);
      console.log(`new user: ${user_id}`)
    }
    else {
      console.log(`known user:  ${user_id}`)
    }
    return user_id;
  }

	const flow={
		start: {
			message: async(params) => {
        setCurrentUser(getLoggedUser());
         return t('welcome_message')
      },
      component:
        <>
          <ScrollToBottomButton/>
          <CancelRequestBtn />
        </>,
			path: "dialog"
		},
 		dialog: {
			message: async (params) => {
        console.log(`dialog: message ${params.userInput}`)
       // await params.injectMessage(<DataMarker timestamp = { Date.now() } validate = { true } sender={'dialog'} input={params.userInput}/>, "user");
        //обнулить предыдущий запрос
        requestId = null;
        try {
          //запрос пользователя
          await sendUserMessage(params);
          if( errorHandler ) { 
            setError({m: t(errorHandler.message), t: new Date() })
            return;
          }
          
          //ожидание ответа
          console.log(`dialog: message requestId ${requestId}`)
          let tid = setTimeout(async () => {
              setCancelBtn({ id: requestId, show: true}); 
          },15*1000)
          let res = await ServiceResponse({ requestId, params });

          setCancelBtn({ id: null, show: false});
          clearTimeout(tid);
          
          if( res && res.error ) {
            if( res.error == "user_canceled") return '';
            if( res.message){
              setError({m: t(res.message, res.key), t: new Date()});
              return ;
            }
            if( res.error.message) {
              setError({m: res.error.message, t: new Date()});
              return ;
            }
            else {
              setError({m: t('error.get_answer'), t: new Date()});
              return; 
            }
          }

          //if has content
          if( res.content && res.content.length ){
              answerContent = res.content;
              //if has context
              if( res.context && res.context.length){
                  answerContext = {context: res.context, url: res.context_url, title: res.context_title };
              }
          }

          //answer has options
          if( res.options ){
            answerOptions = res.options;
          }

          //показываем ответ и ждем новый запрос
          return res.answer;

        } catch(err) {
          console.log(`error while waiting ServiceResponse: ${params.userInput}`);
          setError( {m: t('error.get_answer'), t: new Date()})
          return;
        }
			},
      component: async() => {
        if( answerContent ) {
          let readyContent = await loadContent(answerContent);
          let docContent = readyContent.filter(c => c.content_type == 'doc');
          let element = <></>;
          if( docContent && docContent.length ){
             element = <BotBubble content = { docContent }  context = { answerContext }/>
          } 
          answerContent = null;
          readyContent = readyContent.filter(c => c.content_type != 'doc');
          if ( readyContent && readyContent.length )
             element = <div className="gallery">{ element }<Gallery content = { readyContent } /></div>;
          else element = <div className="gallery">{ element }</div>;
          return element
        }
        else return;
      },
      options: async() => {
        console.log(`OPTIONS: ${JSON.stringify(answerOptions)}`)
        return answerOptions ? answerOptions : [] 
      },
			path: () => {
        errorHandler = null;
        answerOptions = null;
        answerContent = null;
        return  "dialog"
      }
		}
	}
  
  return (
    <Suspense fallback={<div>Loading...</div>}>
    <ChatBotProvider>
      <ChatContext.Provider 
        value = {{
          currentUserId: userId,
          onGalleryItemClick:  handlePopoverOpen,
          cancelBtn
         }}>
        <ConfigHook />
        <ErrorHook eventMessage = { errorEvent }/>
        <ChatBot flow = { flow } settings= { _Settings } styles = { Styles } />
        {isPopoverActive && (
            <MediaPopoverContainer onClose = { handlePopoverClose } content = { contentData }/>
          )}
      </ChatContext.Provider>
    </ChatBotProvider>
    </Suspense>
  );
}

export default App;

