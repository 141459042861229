import { useMessages } from "react-chatbotify";
import { useState, useEffect } from 'react';


function DataMarker( props ){
    const { timestamp, validate, sender, input } = props;
    const data = timestamp;//new Date(timestamp).toLocaleDateString();
    const { messages } = useMessages();
    const [ showDate, setShowDate ] = useState(true);

  /*  useEffect(() => {
        console.log(`sender: ${sender}  input: ${input}`)
        console.log(`messages last: ${new Date(messages[messages.length - 3].timestamp).getDate()}`)
        messages[messages.length - 2] && console.log(`messages prev: ${new Date(messages[messages.length - 2].timestamp).getDate()}`)
        messages[messages.length - 3] && console.log(`messages before prev: ${new Date(messages[messages.length - 3].timestamp).getDate()}`)
        console.log(`now: ${new Date().getDate()}`)
        console.log(`props timestamp: ${new Date(timestamp)}`)
        console.log(`===============================`)


        if( validate ){
        let now = new Date(timestamp).getDate();
        let previous_date = new Date(messages[messages.length - 1].timestamp).getDate();
        if( now == previous_date ){
            setShowDate(false); 
        }
        //else data = new Date(timestamp).toLocaleDateString();
        }
    },[]);
    
*/
    return (
        <>
            { showDate ? 
                (<div className = 'data-marker'>
                    <div>{ data }</div>
                </div>)
            : (<></>)
            }
        </>
    )
}

export default DataMarker