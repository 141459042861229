import { useToasts } from "react-chatbotify"
import { useEffect } from "react";

export const ErrorHook = (props) => {
    const { eventMessage } = props;
    const { showToast } = useToasts();
    useEffect(() => {
        if(eventMessage){
           showToast(eventMessage.m);
        }

    }, [eventMessage])

    return (<></>);
}