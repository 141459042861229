import {  useSettings, useChatWindow } from "react-chatbotify";
import { useTranslation } from 'react-i18next';


function LanguageSelectorButton(){
    const { updateSettings } = useSettings();
    const { t, i18n } = useTranslation();
        
    const changeLanguage = (lng) => {
      return i18n.changeLanguage(lng).then(() =>{
          return updateChatSettings();
      })
    };
    
    function updateChatSettings(){
      return updateSettings({
          chatInput: { enabledPlaceholderText: t('input_placeholder') },
          chatHistory: {
            viewChatHistoryButtonText: t('load_chat_history'),
            chatHistoryLineBreakText: t('chat_history_line_break_text')
          }
      })
    }
   
    return (
       <>
        <button className="language-button" type="button" onClick={() => changeLanguage('ru')}>РУ</button>
        <button className="language-button" type="button" onClick={() => changeLanguage('en')}>EN</button>
       </>
    );
}

export default LanguageSelectorButton